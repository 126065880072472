<template>
  <div class="relative top-0 left-0 right-0 bg-white shrink-0">
    <div class="max-w-screen-xl mx-auto z-100 print:max-w-[720px]">
      <div class="flex w-full px-md">
        <div class="absolute flex items-center h-full text-lg text-minilu-base">
          <div
            class="flex flex-row-reverse gap-2 cursor-pointer place-items-center font-pero text-18"
            @click="back"
          >
            <span class="hidden anim__underline-link minilu-anim-link md:block">
              {{ t('subView.header.back') }}
            </span>
            <FaIcon icon-class="fas fa-chevron-left" />
          </div>
        </div>

        <div class="mx-auto ml-auto">
          <NuxtLink to="/">
            <img
              v-if="$languageCode === 'de'"
              src="~/assets/images/minilu/minilu-logo-de.svg"
              title="minilu logo"
              alt="minilu logo"
              class="h-[32px] w-[165px] my-3"
            />

            <img
              v-if="$languageCode === 'at'"
              src="~/assets/images/minilu/minilu-logo-at.svg"
              title="minilu logo"
              alt="minilu logo"
              class="h-[32px] w-[165px] my-3"
            />

            <img
              v-if="$languageCode === 'nl'"
              src="~/assets/images/minilu/minilu-logo-nl.svg"
              title="minilu logo"
              alt="minilu logo"
              class="h-[32px] w-[165px] my-3"
            />
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
  <div
    class="relative bottom-0 left-0 right-0 h-2 bg-activesection-base print:h-[1px] print:max-w-[720px] mx-auto"
  />
</template>
<script setup lang="ts">
import FaIcon from '@/components/fa-icon.vue';

const { t } = useTrans();

const router = useRouter();

async function back() {
  if (history.state.back) router.back();
  else await router.push('/');
}
</script>
